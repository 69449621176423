import * as React from 'react'

import { AmountStep } from './amount_step'
import { ConfirmationStep } from './confirmation_step'

import type { BulkPurchaseConfig } from 'shared/queries'

type BulkPurchaseCreatorProps = {
  config: BulkPurchaseConfig
}

const BulkPurchaseCreator = ({
  config,
}: BulkPurchaseCreatorProps) => {
  const [step, setStep] = React.useState(1)
  const [amount, setAmount] = React.useState<number>(0)
  const [quotePrice, setQuotePrice] = React.useState<number>(0)

  const handleNext = () => setStep((step) => step + 1)

  const handleBack = () => setStep((step) => step - 1)

  switch (step) {
  case 1: return (
    <AmountStep
      config={config}
      handleNext={(amount: number, quotePrice: number) => {
        setAmount(amount)
        setQuotePrice(quotePrice)
        handleNext()
      }}
    />
  )
  case 2: return (
    <ConfirmationStep
      quotePrice={quotePrice}
      amount={amount}
      handleBack={handleBack}
    />
  )
  }
}

export default BulkPurchaseCreator
