import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import { AppContainer, Loading, SeoHeaders } from 'shared/components'
import { BULK_PURCHASE_CONFIG_QUERY } from 'shared/queries'

import BulkPurchaseCreator from '../bulk_purchase_creator'
import { CreationDisclaimer } from '../bulk_purchase_creator/disclaimers'

import type { RouteComponentProps } from '@reach/router'
import type {
  BulkPurchaseConfig,
  BulkPurchaseConfigData,
  BulkPurchaseConfigVars,
} from 'shared/queries'

const BulkPurchaseCreatorWrapper = ({ config }: { config?: BulkPurchaseConfig }) => {
  if (!config?.creationAllowed) {
    return (
      <CreationDisclaimer>
        La creación de órdenes desde el dashboard está pausada.
      </CreationDisclaimer>
    )
  } else if (!config?.isBusinessHours) {
    return (
      <CreationDisclaimer>
        La creación de órdenes desde el dashboard solo funciona en días hábiles
        entre las 8:30 y 15:00 (hora de Santiago de Chile).
      </CreationDisclaimer>
    )
  } else {
    return <BulkPurchaseCreator config={config} />
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Purchase = (props: RouteComponentProps) => {
  const { loading, data } =
    useQuery<BulkPurchaseConfigData, BulkPurchaseConfigVars>(BULK_PURCHASE_CONFIG_QUERY)

  const bulkPurchaseConfig = data?.bulkPurchaseConfig

  return (loading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <SeoHeaders title='Comprar' />
      <Grid
        container
        maxWidth='sm'
        mx='auto'
      >
        <AppContainer sx={{ p: 3 }}>
          <BulkPurchaseCreatorWrapper config={bulkPurchaseConfig} />
        </AppContainer>
      </Grid>
    </React.Fragment>
  )
}

export default Purchase
