import * as React from 'react'

import { HourglassTop, WhatsApp } from '@mui/icons-material'
import {
  Button,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  darken,
  lighten,
  useTheme,
} from '@mui/material'

import { CurrencyCell, IconCard, Table } from 'shared/components'
import { BulkPurchaseConfig } from 'shared/queries'

import { whatsappLink } from '../../services/bulk_purchases'

export const CreationDisclaimer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme()

  return (
    <Stack spacing={3}>
      <Typography
        variant='h6'
        textAlign='center'
      >
        Crear orden de compra
      </Typography>
      <IconCard
        elevation={0}
        icon={<HourglassTop />}
        color={darken(theme.palette.warning.main, 0.6)}
        bgcolor={lighten(theme.palette.warning.light, 0.8)}
        sx={{ border: 1, borderColor: 'warning.main' }}
      >
        {children}
        <br style={{ display: 'block', content: '""', height: '0.5em' }} />
        Cotiza el mejor precio con nuestro equipo comercial presionando el botón:
      </IconCard>
      <Button
        fullWidth
        target='_blank'
        href={whatsappLink()}
        rel='noopener noreferrer'
        variant='contained'
        color='warning'
        startIcon={<WhatsApp />}
      >
        Cotizar
      </Button>
    </Stack>
  )
}

export const ConfigDisclaimer = ({ config }: { config: BulkPurchaseConfig }) => (
  <Paper
    variant='outlined'
    sx={{ overflow: 'hidden' }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>Límites de compra instantánea</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Monto mínimo</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.minAmount}
          />
        </TableRow>
        <TableRow>
          <TableCell>Monto máximo</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.maxAmount}
          />
        </TableRow>
        <TableRow>
          <TableCell>Máximo diario</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.dailyMaxAmount}
          />
        </TableRow>
        <TableRow>
          <TableCell>Límite diario disponible</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.dailyRemainingAmount}
          />
        </TableRow>
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell
            colSpan={2}
            align='center'
          >
            Puedes comprar montos especiales ingresando el valor y presionando el botón
            {' '}
            <strong style={{ display: 'inline-block' }}>
              &quot;<WhatsApp fontSize='inherit' />&nbsp;Cotizar&quot;
            </strong>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  </Paper>
)
